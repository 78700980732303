// web_PurchaseOrder_shareTheFigure 采购商生成分享图片

const __request = require(`./__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/purchase-controller/shareTheFigureUsingPOST

export default function web_PurchaseOrder_shareTheFigure(pParameter) {
  if (!pParameter) pParameter = {};
  const productNo = pParameter.productNo || ''
  const productRemark = pParameter.productRemark || ''
  const amount = pParameter.amount || ''
  const title = pParameter.title || ''
  const qrCodeUrl = pParameter.qrCodeUrl || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/PurchaseOrder/shareTheFigure'
  params.data = {
    productNo,
    productRemark,
    amount,
    title,
    qrCodeUrl
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

