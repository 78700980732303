const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/home-config-controller/getHotCityUsingPOST
const web_common_visitor_getHotCity = () => {
  const p = {
    method: 'post',
    urlSuffix: '/web/common/visitor/getHotCity',

  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_visitor_getHotCity;
