/* jshint esversion: 6 */
// web_common_isTakeEffect 检验下单是否有效

const __request = require(`./__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/isTakeEffectUsingPOST

export default function web_common_isTakeEffect(pParameter) {
  if (!pParameter) pParameter = {};
  const productNo = pParameter.productNo || ''
  const productId = pParameter.productId || ''
  const departTime = pParameter.departTime || ''
  const total = pParameter.total || 0
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/isTakeEffect'
  params.data = {
    productNo,
    productId,
    departTime,
    total
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
