// web_common_findRetailPrice 查询建议零售价配置

const __request = require(`./__request/__request_contentType_json`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/addTourOrderUsingPOST

export default function web_common_findRetailPrice(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/findRetailPrice'
  params.data = {}
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
